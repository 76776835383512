var $cart = $('.cart');
var $programCounter = $cart.find('.program-counter');

initCart();

function addProgram(){
    $(this).show();

    var currentCount = $programCounter.html();
    var iteratedCount = parseInt(parseInt(currentCount) + 1);

    $programCounter.html(iteratedCount);
}

function removeProgram(){
    var currentCount = $programCounter.html();
    var decrementedCount = parseInt(parseInt(currentCount) - 1);

    if(decrementedCount < 1){
        $cart.hide();
    }

    $programCounter.html(decrementedCount);
}

function initCart(){
    var programsCookie = Cookies.get('programs');

    if(programsCookie === undefined || JSON.parse(Cookies.get('programs')).length < 1){
        $programCounter.html('0');
    }
    else{
        var programs = JSON.parse(programsCookie);
        $programCounter.html(programs.length.toString());
        $cart.show();
    }
}
